
import { Component, Prop, Watch, PropSync } from "vue-property-decorator";
import MRList from "./MRList.vue";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin } from "@/mixins";
import {
  MR,
  MRFilterQueryPayload,
  MRAllPayload,
  SortQueryPayload,
} from "@/store/modules";
import { queryFilterHasChange, filterEmptyQuery } from "@/utility";
// import {AnyObject} from '@/utility';
// import { RootState } from '@/store/types';
import { CDataTableSortVal } from "@/@types";
import TechnicianMultiDropdown from "@/pages/technician/filters/TechnicianMultiDropdown.vue";
import { Technician } from "@/store/modules";
import MRStatusFilter from "./form/MRStatusFilter.vue";
import { filter } from "rxjs/operators";

const defaultFilters: MRFilterQueryPayload = {
  completed: 0,
  voided: 0,
};

@Component({
  components: { MRList, TechnicianMultiDropdown, MRStatusFilter },
})
export default class MRListHandler extends mixins(DateFormatMixin, HeMixin) {
  limit = 25;
  // loading: boolean = true;
  colFilter: MRFilterQueryPayload = { ...defaultFilters };

  currentPage = 1;

  sort: SortQueryPayload = {
    sort: "created_at",
    order: "desc",
  };

  sortVal: CDataTableSortVal = {
    column: 'created_at', asc: false
  };

  filterTechs: Technician[] = [];

  filterStatus: string[] = [];

  debounceTimeout?: NodeJS.Timeout;

  /**
   * in ms
   */
  debounceTime = 1000;

  @Prop({ type: Boolean, default: () => false })
  disableTopFilters!: boolean;

  @Prop({ type: Boolean, default: () => true })
  enablePagination = true;

  @Prop(Boolean)
  isLoading?: boolean;

  @Prop(Number)
  total?: number;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @Prop()
  dataSource?: (filters: MRAllPayload) => Array<MR> | Array<MR>;

  @Prop()
  dispatchFetch?: (filters: MRAllPayload) => Promise<void>;

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    console.log(val);
    if (val !== oldVal) {
      this.$nextTick(() => this.fetchRender(this.compilePayload()));
    }
  }

  onClearFilters() {
    this.colFilter = { ...defaultFilters };
    this.filterTechs = [];
    this.filterStatus = [];
    console.log('CLEARED')
  }

  onTechSelect(options: Technician[]) {
    this.filterTechs = options;

    this.load(true);
  }

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  @Watch("colFilter")
  onColFilterChange(
    val: MRFilterQueryPayload | null,
    oldVal: MRFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }

    if (queryFilterHasChange<MRFilterQueryPayload>(val, oldVal)) {
      this.$nextTick(() => this.fetchRender(this.compilePayload()));
    }
  }

  @Watch("sortVal")
  onSortValChange(val: CDataTableSortVal, oldVal: CDataTableSortVal) {
    this.sort = {
      sort: val.column || this.sort.sort || "",
      order: val.asc ? "asc" : "desc",
    };
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  onPageChange(page: number) {
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1)),
        })
      )
    );
  }

  onStatusSelect(selected: string[]) {
    this.filterStatus = selected;
    this.load(true);
  }

  mounted() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: MRAllPayload) {
    if (this.dispatchFetch) {
      return this.dispatchFetch(payload);
    }
    await MR.dispatch("fetchData", payload);
  }

  get items(): Array<MR> {
    if (this.dataSource) {
      if (typeof this.dataSource === "function") {
        return this.dataSource(this.compilePayload());
      }

      return this.dataSource;
    }
    const data = MR.query()
      .orderBy(this.sort.sort || "created_at", this.sort.order)
      .withAll()
      .get();

    return data;
  }

  get loading(): boolean {
    if (typeof this.isLoading !== "undefined") {
      return !!this.isLoading;
    }
    return !!MR.store().state.entities.mr.fetching;
  }

  get numPages(): number {
    let total = 0;
    if (typeof this.total === "undefined") {
      total = MR.store().state.entities.mr.total;
    } else {
      total = +this.total;
    }

    if (!total) {
      return 0;
    }
    // console.log('t', Math.ceil(total / this.limit), 1);
    return Math.max(Math.ceil(total / this.limit), 1);
  }

  load(debounce = false) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    if (!debounce) {
      this.$nextTick(() => this.fetchRender(this.compilePayload()));
      return;
    }

    this.debounceTimeout = setTimeout(
      () => this.$nextTick(() => this.fetchRender(this.compilePayload())),
      this.debounceTime
    );
  }

  compilePayload(merge: Partial<MRAllPayload> = {}): MRAllPayload {
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: this.compileFilter() },
      ...merge,
    };
  }

  compileFilter(): MRAllPayload["filter"] {
    let f = filterEmptyQuery(this.colFilter);

    if (this.filterTechs && this.filterTechs.length) {
      f.techID = (this.filterTechs || []).map((tech) => tech.ID).join(",");
    }

    const timeRange = [];

    if (this.colFilter.startDate || this.colFilter.endDate) {
      timeRange.push(this.colFilter.startDate || "");
      timeRange.push(
        this.colFilter.endDate || new Date(Date.now()).toISOString()
      );
      delete f.startDate;
      delete f.endDate;
    }

    if (timeRange.length) {
      f.created_at = timeRange.join(",");
    }

    if (this.filterStatus) {
      const statusFilter = this.filterStatus.reduce(
        (acc: MRFilterQueryPayload, filter) => {
          switch (filter) {
            case "hide_approve":
              acc.approved = 0;
              break;
            case "hide_open":
              acc.approved = 1;
              acc.completed = 1;
              // acc.voided = 1;
              break;
            case "completed":
              // remove this filter so that completed shows up
              delete acc.completed;
              break;
            case "voided":
              // remove this filter so that completed shows up
              delete acc.voided;
              break;
            case "paid":
              acc.paid = 1;
              break;
            case "unpaid":
              acc.paid = 0;
              break;
          }
          return acc;
        },
        f
      );

      f = statusFilter;
    }

    return { ...f };
  }

  /*get pagination(): boolean | AnyObject {
    const total = MR.store().state.entities.mr.total;
    if(total) {
      console.log('test', Math.min(Math.ceil(total / this.limit), 1) );
      return {
        pages: Math.min(Math.ceil(total / this.limit), 1),
        activePage: 2
      };
    }
    return false;
  }*/
}
