
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { MR } from "@/store/modules";
import { CurrencyFormatMixin } from "@/mixins";

interface CallOutItem {
  status: boolean;
  title: string;
  successColor?: string;
  faIcon?: string;
}

@Component({
  components: {},
})
export class MRStatusCallout extends mixins(CurrencyFormatMixin) {
  @Prop(MR)
  private record!: MR;

  isApproved() {
    return !!this.record.approved;
  }

  isComplete() {
    return !!this.record.completed;
  }

  isVoided() {
    return !!this.record.voided;
  }

  isPaid() {
    return !!this.record.paid;
  }

  get items(): CallOutItem[] {
    return [
      {
        status: this.isApproved(),
        title: "Approved",
        successColor: "primary",
        faIcon: "file-certificate",
      },
      {
        status: this.isComplete(),
        title: "Complete",
        faIcon: "check-circle",
      },
      {
        status: this.isVoided(),
        title: "Voided",
        successColor: "danger",
        faIcon: "handshake-alt-slash",
      },
      {
        status: this.isPaid(),
        title: "Paid",
        faIcon: "file-invoice-dollar",
      }
    ];
  }
}

export default MRStatusCallout;
