
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { MR, MRNote } from "@/store/modules";
import {  HeMixin, DateFormatMixin } from "@/mixins";

@Component({
  components: {}
})
export class MRNotes extends mixins(HeMixin, DateFormatMixin) {
  @Prop(MR)
  private record!: MR;

  get notes(): MRNote[] {
    if(!this.record || !this.record.notes || !this.record.notes.length) {
      return [];
    }

    return this.record.notes;
  }

}

export default MRNotes;
